import { throttle, debounce } from 'throttle-debounce';
import { CountUp } from 'countup.js';

const revealContainers = document.querySelectorAll('.reveal-container');

function reveal() {
	function init() {
		addEventListeners();
	}

	function addEventListeners() {
		window.addEventListener('scroll', throttle( 300, checkPosition ));
		window.addEventListener('resize', throttle( 300, checkPosition ));
	}

	function checkPosition() {
		revealContainers.forEach( function(revealContainer) {
			if ( isInViewport(revealContainer) && checkDevice(revealContainer) && !revealContainer.classList.contains('revealed') ) {
				var index = 0;
				var revealOffset = 0;
				var revealElements = [].slice.call(revealContainer.querySelectorAll('.reveal'), 0);
				var orderedRevealElements = [];

				// Order reveal elements
				revealElements.forEach( function(revealElement, i) {
					var order = parseInt(revealElement.dataset.revealOrder);

					if ( revealElement.offsetParent !== null ) {
						orderedRevealElements[order] = revealElement;
					}
				});

				orderedRevealElements.forEach( function(revealElement, i) {
					if ( revealElement.dataset.revealOffset ) {
						revealOffset = revealOffset + parseInt(revealElement.dataset.revealOffset);
					}

					if ( !revealElement.classList.contains('revealed') ) {
						setTimeout( function() {
							revealElement.classList.add('revealed');
							countUp(revealElement);
						}, 200 * index + revealOffset);
					}
	
					index++;

					if ( i >= orderedRevealElements.length - 1) {
						setTimeout( function() {
							revealContainer.classList.add('reveal-finished');
						}, 200 * index + revealOffset + 600);
					}
				});
			}
		});
	}

	function checkDevice(element) {
		const viewPortWidth = window.innerWidth;
		var mobile = false;
		var desktop = false;

		element.classList.forEach( function(className) {
			if ( className.indexOf( 'mobile' ) !== -1 ) {
				mobile = true;
			}

			if ( className.indexOf( 'desktop' ) !== -1 ) {
				desktop = true;
			}
		});

		if ( mobile && desktop || !mobile && !desktop ) {
			return true;
		}

		if ( mobile && viewPortWidth < 970 ) {
			return true;
		}

		if ( desktop && viewPortWidth >= 970 ) {
			return true;
		}

		return false;
	}

	function countUp(element) {
		if ( ! element.classList.contains( 'reveal--count' ) ) {
			return;
		}

		element.querySelectorAll( '[data-count]' ).forEach( function(countElement) {
			var counter = new CountUp(countElement, parseInt(countElement.dataset.count), {
				'suffix': countElement.dataset.suffix,
				'duration': 1.5
			});

			counter.start();
		});
	}

	init();
	checkPosition();
}

if ( revealContainers.length > 0 ) {
	reveal();
}

function isInViewport(el) {
    var rect = el.getBoundingClientRect();

    return rect.bottom > 0 &&
        rect.right > 0 &&
        rect.left < (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */ &&
        rect.top < (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */;
}

export default isInViewport;
