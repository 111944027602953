const buttons = document.querySelectorAll('.m-account-interests__interests-block');

function initButtons() {

    const selectAllInterest = document.querySelector('.m-account-interests__select-all');

    buttons.forEach(function(link) {
        link.addEventListener('click', function(evt) {

            if(link.classList.contains('m-account-interests__interests-block--active')) {
                link.classList.remove('m-account-interests__interests-block--active');
                link.querySelector('input').disabled = 'disabled';
            } else {
                link.classList.add('m-account-interests__interests-block--active');
                link.querySelector('input').disabled = null;
            }

            var check = true;

            buttons.forEach(function(button) {

                if(!button.classList.contains('m-account-interests__interests-block--active')) {

                    if( selectAllInterest.classList.contains('active') ) {
                        selectAllInterest.classList.remove('active');

                    }

                    check = false;
                }

                if(check) {
                    if( !selectAllInterest.classList.contains('active') ) {
                        selectAllInterest.classList.add('active');
                    }
                }
            });
        });
    });

    if(selectAllInterest) {
        selectAllInterest.addEventListener('click', function(){
            selectAllInterests(selectAllInterest);
        });
    }

    function selectAllInterests(clickedButton) {

        var check = false;

        if( clickedButton.classList.contains('active') ) {
            clickedButton.classList.remove('active');
        } else {
            check = true;
        }

        buttons.forEach(function(link) {
            if(!link.classList.contains('m-account-interests__interests-block--active') && check) {
                link.classList.add('m-account-interests__interests-block--active');
                link.querySelector('input').disabled = null;
            } else if (link.classList.contains('m-account-interests__interests-block--active') && !check){
                link.classList.remove('m-account-interests__interests-block--active');
                link.querySelector('input').disabled = 'disabled';
            }
        });

        if(selectAllInterest && check) {
            selectAllInterest.classList.add('active');
        }

    }
}

if( buttons ) {
	initButtons();
}
