const switches = document.querySelectorAll('.m-home-intro__switch-container');

function initSwitches() {
    const main = document.getElementsByClassName('p-homepage');
    const main_2 = document.getElementsByClassName('p-premium-info');

    switches.forEach(function(link) {
        link.addEventListener('click', switched);
    });

	function switched() {

        switches.forEach(function(link) {

            if(link.classList.contains('active') ) {
                link.classList.remove('active');

                if(main[0]) { main[0].classList.remove('switched'); }
                if(main_2[0]) { main_2[0].classList.remove('switched'); }
                
            } else {
                link.classList.add('active');

                if(main[0]) { main[0].classList.add('switched'); }
                if(main_2[0]) { main_2[0].classList.add('switched'); }
                
            }
        });
    }
}

if( switches ) {
	initSwitches();
}