const packageOptions = document.querySelectorAll('.m-register-form__select-package__option');

function handlePaymentIntervalSelect(){

    packageOptions.forEach(function(packageOption) {
        packageOption.addEventListener('click', switched);
    });

    function switched(event) {
        packageOptions.forEach(function(packageOption) {
            packageOption.classList.remove('m-register-form__select-package__option--active');
        });

        event.currentTarget.classList.add('m-register-form__select-package__option--active');

        document.querySelector('input[name="paymentInterval"]').value = event.currentTarget.dataset.packageType;

    }
}
if ( packageOptions ) {
    handlePaymentIntervalSelect();
}
