const mastHead = document.querySelector('.m-main-header');

let scrollY = 0;

const getScrollY = () => (window.pageYOffset || document.documentElement.scrollTop)
  - (document.documentElement.clientTop || 0);

const openMenu = () => {
  scrollY = getScrollY();

  document.documentElement.classList.add('is-mobile-nav-visible');

  // Prevent overflow hidden change making scroll to top visible
  setTimeout(() => {
    document.documentElement.classList.add('is-mobile-nav-visible--active');
  }, 175);
};

const closeMenu = () => {
  document.documentElement.scrollTop = scrollY;
  document.documentElement.classList.remove('is-mobile-nav-visible');
  document.documentElement.classList.remove('is-mobile-nav-visible--active');
  window.scrollTo(0, scrollY);
};

const handleClick = (e) => {
  e.preventDefault();

  if (!document.documentElement.classList.contains('is-mobile-nav-visible')) {
    openMenu();
  } else {
    closeMenu();
  }
};

const button = document.getElementById('toggle-nav');

if (mastHead && button) {
  button.addEventListener('click', handleClick);
}
