const buttons = document.querySelectorAll('.scroll-to-top');
const anchors = document.querySelectorAll("a[href^='#']");

import smoothscroll from 'smoothscroll-polyfill';

function initButtons() {

    smoothscroll.polyfill();

    buttons.forEach(function(link) {
        link.addEventListener('click', click);
    });

    anchors.forEach(function(anchor) {
        anchor.addEventListener('click', anchorScroll);
    });

    function click() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    function anchorScroll(e) {
        e.preventDefault();

        const id = e.target.getAttribute("href");
        const new_id = id.substring(1);

        const item = document.getElementById(new_id);

        item.scrollIntoView({
            behavior: 'smooth'
        });
    }

}

if( buttons || anchors ) {
	initButtons();
}
