var items = document.querySelectorAll('.m-newsletter-settings__select-block');
var toggleAll = document.querySelector('.m-newsletter-settings__toggle-all');
var inputs = document.querySelectorAll('input[type="checkbox"]');

function initButtons() {

    toggleAll.addEventListener('click', function(e) {
        toggleAllItems(e.target);
    });

    items.forEach(function(item) {

        item.addEventListener('click', function() {

            var input = item.querySelector('input');

            input.checked = !input.checked;

        });
    });

    function allChecked() {

        for(var i=0;i<inputs.length;i++) {
            if(inputs[i].checked === false) {
                return false;
            }
        }
        return true;
    }

    function toggleAllItems(btn) {

        var newValue = !allChecked();

        inputs.forEach(function(input) {
            input.checked = newValue;
        });

        if (newValue === false) {
            document.querySelector('.m-newsletter-settings__toggle-all').classList.remove('active');
        } else {
            document.querySelector('.m-newsletter-settings__toggle-all').classList.add('active');
        }




        // var check = false;
        //
        // if( clickedButton.classList.contains('active') ) {
        //     clickedButton.classList.remove('active');
        // } else {
        //     check = true;
        // }
        //
        // const inputs = document.querySelectorAll('.m-newsletter-settings__select-block input');
        //
        // inputs.forEach(function(input) {
        //     input.checked = check;
        // })
        //
        // const button = document.querySelector('.m-newsletter-settings__select-all');
        //
        // if(button && check) {
        //     button.classList.add('active');
        // }


        // if(block.classList.contains('m-newsletter-settings__select-block--disabled')) {
        //     return
        // }

        // if(block.classList.contains('m-newsletter-settings__select-block--selected')) {
        //     return
        // }

        // block.classList.add('m-newsletter-settings__select-block--selected');

    }

    // blocks.forEach(function(block) {
    //     block.addEventListener('click', function(evt) {
    //
    //         const inputs = document.querySelectorAll('.m-newsletter-settings__select-block input');
    //         var check = true;
    //
    //         inputs.forEach(function(input) {
    //             const button = document.querySelector('.m-newsletter-settings__select-all');
    //
    //             if(!input.checked) {
    //
    //                 if( button.classList.contains('active') ) {
    //                     button.classList.remove('active');
    //                 }
    //
    //                 check = false;
    //             }
    //
    //             if(check) {
    //                 if( !button.classList.contains('active') ) {
    //                     button.classList.add('active');
    //                 }
    //             }
    //         })
    //
    //         // if(block.classList.contains('m-newsletter-settings__select-block--disabled')) {
    //         //     return
    //         // }
    //
    //         // if(block.classList.contains('m-newsletter-settings__select-block--selected')) {
    //         //     block.classList.remove('m-newsletter-settings__select-block--selected');
    //         // } else {
    //         //     block.classList.add('m-newsletter-settings__select-block--selected');
    //         // }
    //
    //     });
    // });
}

if (items.length) {
	initButtons();
}
